$account_leftline: 45%;
$cost_leftline: 65%;

@mixin error_field_red {
  border: 1px solid $color--red;
  box-shadow: none;
}

body.toolbar-drawer {
  padding-top: 0 !important;
}

.profile-info__fieldset {
  .picker-radio {
    float: #{$ldirection};
    width: 17%;
  }
  .title-form {
    width: 80% !important;
  }
  .phone_code_container {
    input.field {
      width: 100%;
      margin: 10px 0;
    }
  }
  .title_col,
  .gender_col,
  .sms_col,
  .birthday_col {
    label {
      display: block;
    }
  }
  div.birthday_col {
    select {
      margin-bottom: 10px;
    }
    .change-birth-text {
      color: $color--red;
    }
  }
  .profile_sms_bound {
    margin-bottom: 10px;
  }
}

.sms_col {
  margin-top: 10px;
}

.site-header {
  .site-header__tools {
    .block-template-site-my-mac-v2 {
      display: inline;
      float: #{$rdirection};
      position: absolute;
      #{$rdirection}: 50px;
    }
  }
  .site-header__prefix {
    .site-logo a {
      background: no-repeat 50%;
    }
  }
}

.site-container {
  .profile-page {
    padding: 0 10px;
    .profile-info__fieldset {
      .title_col {
        width: 100% !important;
      }
    }
    .newsletter-info__fieldset {
      .picker-checkbox {
        .picker-label {
          max-width: 85%;
        }
      }
    }
  }
  .account-nav {
    .responsive-container {
      .account-utilities {
        clear: both;
      }
    }
  }
  .account-page__content {
    .account-page__panel {
      .past-purchases {
        padding: 0 15px 0;
      }
    }
  }
  .product__footer {
    a.notify_me {
      float: #{$rdirection};
    }
    .product-item {
      &__add-to-cart p {
        float: #{$ldirection};
      }
    }
  }
  .account-order-history {
    .past-purchases {
      &__item {
        font-size: 0.9em;
        .product-header {
          h6 {
            font-size: 11px;
            &.quantity {
              margin-#{$ldirection}: 29%;
            }
            &.rating {
              margin-#{$ldirection}: 46%;
              width: 13.11111%;
            }
            &.price {
              margin-#{$rdirection}: 32%;
              width: auto;
            }
          }
        }
        .product--teaser {
          .product__qty {
            margin-#{$ldirection}: 28%;
            width: 20%;
          }
          .product__rate {
            margin-#{$ldirection}: 46%;
            width: 13.11111%;
          }
          .product__price {
            margin-#{$ldirection}: 60%;
            font-size: 11px;
          }
          .product__add {
            width: 23%;
            .product__add-to-bag {
              width: 100%;
              font-size: 12px;
            }
          }
        }
        .created-date {
          margin-#{$rdirection}: -16%;
        }
      }
    }
  }
  .mtop {
    &.responsive-container {
      padding: 20px 20px 0 10px;
    }
  }
  .address-form {
    .state,
    .city,
    .phone1,
    .phone2 {
      width: 48%;
      display: inline-block;
    }
    .city,
    .phone1 {
      float: right;
    }
    .district {
      label {
        display: none;
      }
    }
  }
  .logistics-details {
    .date {
      width: 15%;
    }
    .weekday {
      width: 10%;
    }
    .time {
      width: 20%;
    }
  }
}

.sidr .site-navigation {
  position: static !important;
}
.sidr.#{$ldirection} {
  height: 100%;
}

label.error.picker-label {
  color: #b84947;
}

.past-purchases-page {
  .past-purchases {
    .past-purchases {
      &__instructions {
        .past-purchases {
          &__link {
            height: 35px;
            line-height: 35px;
            width: 150px;
          }
        }
      }
    }
  }
}

.orders-list__table td {
  font-size: 0.75rem;
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      padding: 1.5em 0;
      .order-status {
        float: left;
        margin-left: $account_leftline;
      }
      .carrier_name {
        margin-left: $account_leftline;
      }
      .tracking-link {
        margin-left: $account_leftline;
      }
      .order-cost {
        margin-left: $cost_leftline;
      }
    }
  }
}

.sign-in-page {
  .error_messages {
    margin-bottom: 10px;
  }
}

.adpl input.error {
  @include error_field_red;
}

.loyalty-index-page__content {
  .profile-info-panel {
    margin-bottom: 60px;
    .loyalty-customer-info {
      font-size: 2.5em;
      font-weight: bolder;
      padding-left: 20%;
    }
  }
  .loyalty-progress-bar {
    background-image: url(/account/loyalty/progress_bar.tmpl);
    background-size: 100% 100%;
    background-position: 0.5em 0;
    background-repeat: no-repeat;
    min-height: 360px;
    font-weight: bolder;
    text-align: center;
    .loyalty-text-pts-1 {
      font-size: 4em;
      margin-top: 2em;
    }
    .loyalty-text-pts-2 {
      font-size: 3em;
    }
    .loyalty-text-pts-3 {
      font-size: 1.2em;
      font-weight: bold;
      margin-top: 4.75em;
    }
  }
  .profile-error {
    text-align: center;
  }
}
