#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-search-container {
            .pr-rd-search-reviews-input {
              input {
                background-color: $color--white;
                border: 1px solid $color--gray--lighter;
                border-bottom: 0;
              }
              .pr-rd-search-reviews-icon-button {
                border: 0;
              }
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: '有帮助(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: '无用(';
              }
            }
          }
          .pr-modal {
            top: 56%;
          }
        }
      }
    }
  }
  .pr-filter-btn:before,
  .pr-filter-btn:after {
    display: none;
  }
}

.spp_reviews {
  display: none;
}
