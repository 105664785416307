@if $password_strengthen {
  .new-account__fieldset {
    .form-item.password-field {
      width: 100%;
      clear: both;
      padding: 0;
    }
    .form-item-phone {
      clear: both;
    }
    .password-field {
      &__info {
        #{$ldirection}: 100%;
        bottom: 15%;
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .checkout__new-account {
    .password-field {
      &__info-checkout {
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 10px;
      }
    }
  }
  .password-reset-page {
    .password-reset {
      .password-field__info-reset {
        top: 50%;
        transform: translateY(-50%);
        @include breakpoint($bp--small-down) {
          transform: translateY(0);
        }
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color--gray--dark;
      }
    }
  }
}
