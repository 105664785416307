$border_color: #dbdbdb;
$background_color: #d4d4d4;
$order_color: #bdbdbd;
$box_color: #ccc;
$border_valid: #000;

#shipping ol.checkout-progress li.shipping,
#review ol.checkout-progress li.review,
#billing ol.checkout-progress li.billing,
#confirm ol.checkout-progress li.confirm {
  font-weight: bold;
}

.checkout {
  ol.checkout-progress {
    li {
      margin: 0;
      padding: 3px 0;
      width: 25%;
      float: left;
      list-style-position: inside;
      display: list-item;
      list-style-type: decimal;
      line-height: 1.4em;
    }
  }
  .recommended-products-panel {
    display: block;
    .recommended-item {
      float: left;
      width: 30%;
      @include swap_direction(margin, 0 4px 0 4px);
      input {
        @include swap_direction(padding, 5px);
        line-height: 0;
      }
    }
  }
  .offer-code__input {
    text-align: center;
  }
  #one-offer-only {
    text-align: center;
    text-transform: uppercase;
  }
  .additional_info {
    margin-top: 10px;
  }
  .recommended-products-panel {
    .recommended-product-items {
      .info {
        height: 11em;
      }
    }
  }
  .panel {
    .valid_marker {
      display: none;
    }
  }
  .picker-checkbox {
    .picker-label {
      max-width: 85%;
    }
  }
  .signin-privacy {
    .picker-checkbox {
      .picker-label {
        max-width: 100%;
      }
    }
  }
  .invalid_marker {
    display: none;
  }
  .checkout__sidebar {
    .order-summary-panel {
      display: none;
    }
  }
  .address-form {
    .name-fields {
      .form-item {
        width: 100%;
      }
    }
    .city-state-zip {
      .state_select,
      .city_select {
        margin-right: 0;
        width: 48%;
        display: inline-block;
      }
      .state_select {
        float: none;
      }
      .city_select {
        float: right;
      }
      .district_select,
      .postal_code {
        width: 100%;
        margin-right: 0;
        float: none;
      }
      .district_select {
        label {
          display: none;
        }
      }
    }
    select:required:valid {
      border-color: $border_valid;
    }
    .phone-1,
    .phone-2 {
      width: 48%;
      display: inline-block;
    }
    .phone-2 {
      float: right;
    }
  }
}

.samples {
  .product.deactivate {
    .sample-select-button {
      background-color: $background_color;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .picker-checkbox {
    .picker-handle {
      display: none;
    }
  }
  .samples-panel {
    border-top: 1px solid $border_color;
    padding-top: 15px;
  }
  .product-list {
    .product {
      border-bottom: 1px solid $border_color;
      margin: 15px 0;
    }
  }
  .product-img {
    float: left;
    width: 45%;
    padding-right: 20px;
    margin-bottom: 20px;
  }
  .return-link {
    float: left;
    width: 55%;
  }
  .details {
    width: 55%;
    text-align: left;
    line-height: 1.4;
  }
  .product-list {
    overflow: hidden;
  }
  .checkout__panel-title {
    display: block;
  }
  .sample-select-button {
    width: 125px;
  }
  .continue-buttons {
    margin-left: 33%;
  }
}

#shipping {
  .address_form_container,
  #address_form_container {
    .form_element {
      margin: 10px 0;
    }
  }
  .address_controls {
    .radio {
      float: left;
      width: 50%;
    }
  }
}

.border_ruler {
  border: 1px solid $order_color;
  clear: both;
}

.btn {
  margin: 10px 0;
  width: 100%;
  padding: 0 10px;
}

.change_link {
  float: right;
  margin-right: 20px;
}

.transaction-details {
  address {
    font-style: normal;
  }
}

.cart-items {
  .cart-item__total {
    width: 10%;
  }
}

.invoice-options-container {
  .option.type {
    label {
      display: inline-block;
    }
  }
  .picker-radio {
    float: left;
    width: 30%;
  }
  .type {
    margin-top: 10px;
  }
}

a.disabled.continue-checkout {
  background-color: #ccc;
  &:hover {
    text-decoration: none;
    cursor: default;
  }
}

#gift-options {
  .giftmsg_from_container,
  .giftmsg_to_container,
  .giftmsg_message_container {
    margin: 0 10px 10px 0;
  }
  label {
    display: inline-block;
  }
}

#address_form_container {
  .address_title {
    label {
      display: inline-block;
    }
  }
}

#delivery-options {
  label {
    display: block;
  }
  select {
    border-width: 1px;
    height: 59px;
  }
}

#shipping {
  .terms-conditions {
    .picker-checkbox {
      label {
        width: 85%;
      }
    }
  }
}

a.change_link {
  position: relative;
  right: 0;
  top: -30px;
}

.js-dd-input-state,
.js-dd-input-city {
  width: 48% !important;
}

.select2-drop-mask {
  z-index: 9999;
}

[class*='icon--'],
.footer-menu--mobile .field-menu > .menu > li > a:before,
.site-header .site-header__tools .site-bag__contents-recommendations__title-toggle:before,
.site-header
  .site-header__tools
  .site-bag__contents-recommendations.active
  .site-bag__contents-recommendations__title-toggle:before,
.twitter-block__time:after,
.artistry--tweet-feed .tweet-stamp:after,
.shade-slider__choice--active .shade-slider__choice-text:before,
.slider-carousel-control--prev:before,
.slider-carousel-control--next:before,
.carousel .slick-prev:before,
.carousel .slick-next:before,
.collection-detail-formatter__mobile-subnav a:before,
.collection-landing-brick__logo a:before,
.collection-landing-brick .slick-prev:before,
.collection-landing-brick .slick-next:before,
.form--search > .form--inline:before,
.form--search--advanced.has-js-dropdown-open .toggle:after,
.form--search--advanced .toggle:after,
.has-js-dropdown-open .form--search--advanced .toggle:after,
.panel .valid_marker:before,
.picker-checkbox.picker .picker-flag:before,
.select2-container .select2-choice .select2-arrow b:before,
.grid-filter__facet-item--checked:after,
.accordionPanel i:before,
.accordionPanel .active i:before,
.account-order-history .past-purchases .past-purchases__item .view-details-link i:before,
.account-order-history .past-purchases .past-purchases__item.active i:before,
.sec-nav__item--has-sub-nav .sec-nav__link:before,
.shop-the-collection__carousel-controls .prevPage:before,
.shop-the-collection__carousel-controls .nextPage:before,
.social-link--facebook:before,
.social-link--twitter:before,
.social-link--instagram:before,
.artistry--artist .submenu__header a:before,
.filter--region .filter--region--arrow:before,
.finder-step__media-copy:before,
.page--spp__custom_palette .product .shade-picker__tools-sort-toggle:before,
.page--spp__custom_palette .product .shade-picker__tools-sort-toggle.active:before,
.page--spp__custom_palette .product .shade-picker__tools-filters-toggle:before,
.page--spp__custom_palette .product .shade-picker__tools-filters .shade-picker__tools-title:before,
.page--spp__custom_palette .product .shade-picker__tools-filters .shade-picker__tools-title.active:before,
.page--spp__custom_palette .product .shade-picker__tools-filters-clear:before,
.page--spp__custom_palette .product .shade-picker__tools-filters-items a.active:before {
  text-rendering: auto !important;
}

.order-details-page {
  &__content {
    padding: 0 10px;
  }
}

.site-container {
  h2#stage {
    font-size: 25px;
    margin: 15px 0 0 0;
    text-align: center;
    border-bottom: 1px solid #ccc;
    padding: 15px 0 30px 0;
  }
  .checkout-progress {
    margin: 10px;
    li {
      margin-right: 15px;
      list-style-position: inside;
      display: list-item;
      list-style-type: decimal;
      float: left;
    }
  }
  .pg_wrapper {
    .checkout-confirmation-page {
      .confirmation-panel__container {
        background: white;
        .checkout-header {
          border: 0;
          h4.confirmation-panel__title {
            color: black;
            text-align: left;
            margin-left: 15px;
            font-size: 25px;
          }
        }
        .confirmation-panel__content {
          text-align: left;
          padding: 5px 0;
          border: 0;
          .confirmation-panel__order-number {
            color: black;
            padding: 0;
            margin-left: 15px;
            font-size: 20px;
            a {
              color: black;
            }
          }
        }
        .confirmation-panel__actions {
          margin: 0 15px;
          text-align: left;
          padding: 15px 0;
          .confirmation-panel__email-coming {
            color: black;
            padding: 0;
          }
          .confirmation-panel__print-buttons {
            a {
              color: black;
            }
          }
        }
      }
    }
    .checkout__content {
      padding: 0 15px;
    }
  }
  .checkout {
    .checkout {
      &__content {
        #recommended-products-panel {
          display: none;
        }
      }
    }
  }
}

.sign-in-panel {
  header {
    &#new-account-h,
    &#return-account-h {
      margin-bottom: 10px;
    }
  }
}

.adpl {
  .invoice-options-container {
    .option.name {
      margin-bottom: 10px;
    }
  }
  .gift-options {
    .card-message {
      label {
        display: block;
      }
    }
  }
}

#wo_online_image.wo-chat-btn {
  display: none !important;
}

.viewcart,
.shipping-panel,
.payment-panel {
  .checkout_sticky {
    .mobile_sticky_bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 0 15px 15px 15px;
      box-shadow: 0px -3px 6px $box_color;
      z-index: 999;
      margin-bottom: 0px;
    }
  }
}

.viewcart,
.checkout {
  .site-footer {
    display: none;
  }
  .checkout__sidebar {
    padding-bottom: 60px !important;
  }
}

.opc__signin {
  #order-summary-panel {
    display: none;
  }
}

.opc__cart {
  .viewcart-buttons-panel {
    display: block;
  }
}

#completed_transaction_signin {
  .form-item.password {
    input {
      margin-top: 10px;
    }
  }
}
