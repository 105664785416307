.domain-cn {
  .site-navigation {
    font-family: 'Microsoft YaHei', Helvetica, Arial, Sans-Serif;
  }
}

.site-container {
  #product-results {
    .product--orderable {
      &:not(.prod_inv_status-1) {
        .hide-non-orderable-product {
          display: none;
        }
      }
      .product__link-custom {
        display: none;
      }
    }
  }
  .content {
    .field-elc-mpp-content {
      .grid--mpp {
        .prod_inv_status-3 {
          .product__sku-details {
            .product__footer {
              .product__add-to-bag.coming-soon {
                display: none;
              }
            }
          }
        }
      }
    }
    .page--spp__product {
      .product__sku-details {
        .product__footer {
          .product_content_fav_mobile {
            .product__add-to-faves {
              bottom: 70px;
            }
          }
        }
      }
      .prod_inv_status-7 {
        .product__sku-details {
          .product__footer {
            .product__inventory-status {
              .sold-out {
                display: block;
              }
            }
          }
        }
      }
    }
    .page--spp-ultra-wide {
      .product__footer {
        .product__add-to-faves {
          bottom: 50px;
          margin-top: 0;
        }
        .product__inventory-status {
          li.product__inv-status-item {
            margin-bottom: 50px;
          }
          a.notify_me {
            padding: 19px 60px 19px 0;
            width: 100%;
            margin: 0 !important;
            position: absolute;
            text-align: center;
          }
        }
      }
    }
    .product__share-wrapper {
      .product__social-links {
        height: 20px;
        width: 5.7em;
        .icon--weibo {
          background: url(/media/export/cms/weibo_icon.svg) no-repeat center;
          width: 18px;
          height: 18px;
        }
        .icon--weixin {
          background: url(/media/export/cms/weixin_icon.svg) no-repeat center;
          width: 18px;
          height: 18px;
        }
        .icon--renren {
          background: url(/media/export/cms/renren_icon.svg) no-repeat center;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  #email_signup_new_user {
    .picker-checkbox {
      .picker-handle {
        margin: 0 10px 0 2px;
      }
      .picker-label {
        width: 88%;
      }
    }
    .mobile-signup {
      display: none;
    }
  }
  .store-locator {
    .location_info {
      .add-to-favorites {
        display: none;
      }
    }
  }
}

.domain-cn {
  .site-navigation {
    font-family: 'Microsoft YaHei', Helvetica, Arial, Sans-Serif;
  }
}

.site-container {
  .content {
    .field-elc-mpp-content {
      .grid--mpp {
        .prod_inv_status-3 {
          .product__sku-details {
            .product__footer {
              .product__add-to-bag.coming-soon {
                display: none;
              }
            }
          }
        }
      }
    }
    .page--spp__product {
      .product__sku-details {
        .product__footer {
          .product_content_fav_mobile {
            .product__add-to-faves {
              bottom: 70px;
            }
          }
        }
      }
      .prod_inv_status-7 {
        .product__sku-details {
          .product__footer {
            .product__inventory-status {
              .sold-out {
                display: block;
              }
            }
          }
        }
      }
    }
    .page--spp-ultra-wide {
      .product__footer {
        .product__add-to-faves {
          bottom: 50px;
          margin-top: 0;
        }
        .product__inventory-status {
          li.product__inv-status-item {
            margin-bottom: 50px;
          }
          a.notify_me {
            padding: 19px 60px 19px 0;
            width: 100%;
            margin: 0 !important;
            position: absolute;
            text-align: center;
          }
        }
      }
    }
    .product__share-wrapper {
      .product__social-links {
        height: 20px;
        width: 5.7em;
        .icon--weibo {
          background: url(/media/export/cms/weibo_icon.svg) no-repeat center;
          width: 18px;
          height: 18px;
        }
        .icon--weixin {
          background: url(/media/export/cms/weixin_icon.svg) no-repeat center;
          width: 18px;
          height: 18px;
        }
        .icon--renren {
          background: url(/media/export/cms/renren_icon.svg) no-repeat center;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  #email_signup_new_user {
    .picker-checkbox {
      .picker-handle {
        margin: 0 10px 0 2px;
      }
      .picker-label {
        width: 88%;
      }
    }
    .mobile-signup {
      display: none;
    }
  }
  .page--spp {
    &__product {
      .prod_inv_status-2 {
        .product {
          &__footer {
            .temp-out-of-stock_notifyme-spacing {
              .temp-out-of-stock {
                .notify-status {
                  margin-top: 10px;
                  .notify_me {
                    position: relative;
                    padding: 19px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .search-results {
    .pagination {
      margin-bottom: 20px;
    }
  }
}

#colorbox {
  #cboxContent {
    .footer-wechat {
      &__overlay-content {
        padding: 22px 0;
      }
    }
  }
}

.contact-us-page {
  .contact-nav {
    .js-live-chat {
      display: none;
    }
  }
}

.site-footer {
  .site-footer--top {
    .mobile-help {
      &__buttons {
        .footer-liveperson-link {
          display: none;
        }
        .grid--item {
          &:nth-of-type(2n + 2) {
            padding: 0;
            margin: 0 auto;
            float: none;
            width: 55%;
          }
        }
      }
    }
  }
}
//Roboto-Mono font replaces the Tstar font
.grid--mpp__item {
  .product--teaser {
    .product_header_details {
      .product_rgn_name_below_subline {
        @if $fonts_update == false {
          font-family: $roboto-mono_regular;
        }
      }
    }
  }
}

.page--spp-ultra-wide {
  .product--full {
    .product_rgn_name_below_subline {
      font-family: $roboto-mono_regular;
    }
  }
}

.product-full--enhanced {
  &.product-full {
    .product-details {
      &__bopis-spp-container {
        .bopis-spp-block {
          background-color: $color--black;
          .spp-shipping-head,
          .spp-shipping-text,
          .product-offer__edd a {
            color: $color--white;
          }
        }
      }
    }
  }
}

.content-block--content {
  .gnav-secondary-formatter-wrapper {
    &.slick-initialized {
      padding: 0;
    }
  }
}
