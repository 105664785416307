.sign-in-page,
.sign-in-panel {
  margin: 2em 0 7em 0;
  section.return-user,
  .sign-in-container ul.error_messages {
    padding: 0 2em;
  }
  div.sign-in-container {
    vertical-align: middle;
    height: 100%;
    @include breakpoint($bp--largest-up) {
      min-width: calc(#{$bp--small-up} + 10px);
      padding-left: calc(50% - #{$bp--small-up});
    }
  }
  div.border-right {
    @include breakpoint($bp--largest-up) {
      border-right: 1px solid $color--gray--tier5;
    }
  }
  .sms-otp {
    &__item {
      input[type='submit'] {
        position: relative;
        top: 2.9px;
        margin-right: -5px;
      }
      label.picker-label {
        float: none;
      }
      input[name='MOBILE_NUMBER'] {
        display: inline;
        width: calc(100% - 77px);
      }
    }
  }
  .return-user,
  .sms-otp {
    &__item {
      padding: 1em 0;
    }
  }
  @if $fonts_update == true {
    .adpl {
      $adaptive-placeholder-acc-cn: (
        height: 4em,
        margin: 1em,
        font-size: 13px
      );
      .return-user,
      .sms-otp {
        input[type='email'],
        input[type='tel'],
        input[type='password'],
        input[type='text'] {
          @include adaptive-placeholder(
            (
              height: map-get($adaptive-placeholder-acc-cn, height),
              margin: map-get($adaptive-placeholder-acc-cn, margin)
            )
          );
        }
      }
      .sms-otp {
        &__fieldset {
          form#sms_pincode {
            position: relative;
            .field-container-section.sms-otp-container {
              top: -14px;
              position: relative;
              .sms-otp__item {
                padding-top: 0;
              }
            }
          }
        }
        .field-container.sign {
          &:after {
            display: none;
          }
        }
        &__item {
          input[type='submit'] {
            &.float-right.btn-move-up {
              @include adaptive-placeholder(
                (
                  height: map-get($adaptive-placeholder-acc-cn, height),
                  margin: map-get($adaptive-placeholder-acc-cn, margin)
                )
              );
              top: -61px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  div.signin-banner-container {
    padding: 0 2em;
    @include breakpoint($bp--largest-up) {
      padding: 0 3em;
    }
  }
  label:not(.picker-label) {
    display: block;
    font-size: 1.3em;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
  }
}

.sign-in-panel {
  .sms-otp {
    #signin_new_mobile {
      .sms-otp {
        &__item {
          position: relative;
          input[type='submit'] {
            position: absolute;
            right: 0px;
            top: 15px;
            margin: 0px;
            height: calc(4.8335em - -2px);
          }
        }
      }
    }
  }
}

// inherited from mac_base/scss/20-global/_site-header.scss
// block-template-site-my-mac-v1 >> block-template-site-my-mac-cn-v1
.site-header {
  .site-header__tools {
    .block-template-site-my-mac-cn-v1 {
      display: inline;
      float: #{$rdirection};
      position: absolute;
      #{$rdirection}: 50px;
      @include breakpoint($bp--largest-up) {
        position: relative;
        #{$rdirection}: auto;
        width: 41%;
      }
      form.js-sms-pincode {
        .picker-label {
          width: 86%;
        }
      }
    }
  }
}

@if $gnav_update == true {
  @include breakpoint($bp--largest-up) {
    //968px
    .site-header .site-header__fixed-wrapper {
      .site-header__suffix .site-header__tools {
        .block-template-site-my-mac-cn-v1 {
          width: auto;
        }
      }
    }
  }
}

input[type='SUBMIT'].loading {
  background: $color--black url(/media/dev/global/icon_loading.gif) no-repeat center center;
}

.btn-move-up,
.checkout__content .btn-move-up.loading {
  margin-top: -4.15em;
  margin-bottom: 0;
}

.checkout {
  &__content {
    .invoice-change-email {
      .loading {
        margin: 0;
      }
    }
  }
}

.grayout_dummy_email {
  color: $white;
}

#signin_new_mobile {
  .sms-otp-login-or-regist-info {
    clear: both;
  }
}
