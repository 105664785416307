.product-foundation-vto {
  .product-price-v1 {
    float: $ldirection;
  }
  .container {
    .product-foundation-vto {
      &__info-container {
        .product-size {
          @include swap_direction(margin, 0 0 0 6px);
        }
      }
      &__all-shades {
        @include swap_direction(margin, 36px 7px 17px 3px);
      }
    }
  }
  &__btn {
    bottom: 13%;
  }
}

.product-full--enhanced {
  .product-vto {
    .cta-vto {
      top: -44px;
    }
  }
}
