.sec-nav {
  &.site-banner__sticky-fix {
    position: relative;
  }
}
.basic-responsive-v1 {
  div  {
    a {
      &:not(.chat--icon) {
        padding: 0;
      }
    }
  }
}
